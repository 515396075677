import { Register } from '@/api/index';

export async function userRegister(data) {
    let res = await Register.userRegister(data)
    return res
}

export async function verifyCode(data) {
    let res = await Register.verifyCode(data)
    return res
}
// 修改密码
export async function changePwd(data){
    let res = await Register.changePwd(data)
    return res;
}

// 获取邮箱验证码
export async function sendEmailCode(data){
    let res = await Register.sendEmailCode(data)
    return res;
}